@font-face {
    src: url(./fonts/CircularSpotifyText-Bold.otf);
    font-family: Spotify;
    font-weight: bold;
}

@font-face {
    src: url(./fonts/CircularSpotifyText-Book.otf);
    font-family: Spotify;
    font-weight: normal;
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    background: black;
    font-family: Spotify, sans-serif;
    text-align: center;
    min-width: 320px;
    margin: 0;
}

html,
body {
    /* Old school, but 100vh does not account for browser chrome! */
    /* Just remember that every ancestor must be set to 100% height */
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: fixed;
    user-select: none;
    -webkit-overflow-scrolling: touch;
}

/* Ignore browser chrome on landscape small screens, else we have */
/* all kinds of issues */
@media screen and (orientation: landscape) and (max-height: 480px) {
    html,
    body {
        height: 100vh;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
    line-height: inherit;
    margin: 0;
}

#root {
    height: 100%;
}

#modal-root {
    position: relative;
    z-index: 10;
}
